import { useStaticQuery, graphql } from "gatsby"

export const useArticles = () => {
  const query = useStaticQuery(
    graphql`
      query Articles {
        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
          edges {
            node {
              id
              rawBody
              timeToRead
              excerpt
              fields {
                slug
              }
              frontmatter {
                author
                date
                description
                tags
                title
              }
            }
          }
        }
      }
    `
  )
  return query.allMdx.edges
}
