import * as React from "react"
import { FC, ReactNode } from "react"
import { Text } from "theme-ui"

export interface IPropsCode {
  children: ReactNode
}

export const Code: FC<IPropsCode> = ({ children }) => {
  return <Text variant="code">{children}</Text>
}
