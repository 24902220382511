import * as React from "react"
import { FC } from "react"
import { Box, Container, Grid, Text } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Author } from "components/Author"
import { Footer } from "components/Footer"
import { GridOverlay } from "components/GridOverlay"
import { HeroPost } from "components/HeroPost"
import { Layout } from "components/Layout"
import { More } from "components/More"
import { PostStyling } from "components/PostStyling"
import { SEO } from "components/Seo"
import { TagList } from "components/TagList"

const LazyDisqus = React.lazy(() => import("components/Disqus"))

interface Mdx {
  body: any
  timeToRead: number
  id: string
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    author: string
    avatar: {
      childImageSharp: GatsbyImageFluidProps
    }
    date: string
    title: string
    tags: string[]
    description: string
    jobTitle: string
    image: {
      childImageSharp: GatsbyImage
    }
  }
}

export interface IPropsPost {
  data: {
    mdx: Mdx
  }
}

const Post: FC<IPropsPost> = ({ data: { mdx } }) => {
  const isSSR = typeof window === "undefined"
  const showDisqus = false // Not showing Disqus for now
  const {
    author,
    avatar,
    date,
    title,
    tags,
    description,
    jobTitle,
    image,
  } = mdx.frontmatter
  const {
    timeToRead,
    id,
    excerpt,
    fields: { slug },
  } = mdx

  return (
    <Layout>
      <GridOverlay />
      <SEO
        title={title}
        location={slug}
        description={description}
        author={author}
        date={date}
        excerpt={excerpt}
        image={image}
      />
      <HeroPost
        date={date}
        description={description}
        timeToRead={timeToRead}
        title={title}
      />
      <Container>
        <Grid
          sx={{ pb: [null, null, null, 10], alignItems: "baseline" }}
          variant="base"
        >
          <Container variant="pipe" sx={{ pt: [8, null, null, null, 14, 15] }}>
            <PostStyling>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </PostStyling>
            {tags && (
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Text
                  sx={{
                    fontSize: 3,
                    mr: [2],
                    color: "textMuted",
                  }}
                >
                  Tags{" "}
                </Text>

                <TagList tags={tags} />
              </Box>
            )}
            {/* Do not show Disqus on server side rendered page because document is not available */}
            {!isSSR && showDisqus && (
              <Box sx={{ mt: 13 }}>
                <React.Suspense fallback={<div />}>
                  <LazyDisqus slug={slug} title={title} />
                </React.Suspense>
              </Box>
            )}
          </Container>
          <Box
            sx={{
              position: "relative",
              alignSelf: "flex-start",
              gridColumn: ["5/9", null, null, null, "11/13"],
              order: [-1, null, null, null, "initial"],
              mb: [7],
            }}
          >
            <Author author={author} avatar={avatar} jobTitle={jobTitle} />
          </Box>
        </Grid>
      </Container>
      <More articleId={id} />
      <Footer />
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`query Post($id: String) {
  mdx(id: {eq: $id}) {
    body
    id
    excerpt
    frontmatter {
      image {
        childImageSharp {
          gatsbyImageData(width: 1200, layout: FIXED)
        }
      }
      avatar {
        childImageSharp {
          gatsbyImageData(width: 400, layout: CONSTRAINED)
        }
      }
      author
      date
      description
      jobTitle
      tags
      title
    }
    timeToRead
    fields {
      slug
    }
  }
}
`
