import * as React from "react"
import { FC } from "react"
import { shuffle } from "lodash/fp"
import { Container, Grid, Heading, Box } from "theme-ui"

import { RelatedPost } from "components/RelatedPost"
import { useArticles } from "hooks/use-articles"

export interface IPropsMore {
  articleId: string
}

export const More: FC<IPropsMore> = ({ articleId }) => {
  const articles = useArticles()
  const otherArticles = shuffle(
    articles.filter((a) => a.node.id !== articleId)
  ).slice(0, 3)
  return (
    <Container
      sx={{
        py: [15, null, 14, null, 15, 16],
      }}
    >
      <Grid variant="base">
        <Heading
          as="h4"
          sx={{
            letterSpacing: "tight5",
            wordSpacing: "0.1em",
            fontSize: [10, 12, null, null, 15],
            gridColumn: "span 12",
            textAlign: "center",
            mb: [7, null, null, null, 12],
          }}
        >
          Our thinking
        </Heading>
      </Grid>
      <Grid variant="base" as="ul">
        {otherArticles &&
          otherArticles.map((article) => {
            const { id, timeToRead } = article.node
            const { slug } = article.node.fields
            const {
              author,
              date,
              title,
              description,
              tags,
            } = article.node.frontmatter
            return (
              <Box
                as="li"
                key={id}
                sx={{
                  gridColumn: ["span 12", "span 4"],
                  mb: [10, 0],
                  ":last-child": {
                    mb: 0,
                  },
                }}
              >
                <RelatedPost
                  author={author}
                  date={date}
                  description={description}
                  tags={tags}
                  slug={slug}
                  title={title}
                  timeToRead={timeToRead}
                />
              </Box>
            )
          })}
      </Grid>
    </Container>
  )
}

export default More
