/** @jsx jsx */
import * as React from "react"
import { FC, ReactNode } from "react"
import { MDXProvider } from "@mdx-js/react"
import { jsx, Box, Heading, Link, Text, SxStyleProp } from "theme-ui"

import { Underline } from "components/Underline"
import { Orphans } from "components/Orphans"
import { Code } from "components/Code"
import { Badge } from "components/Badge"
import { IconQuote } from "icons"
import "katex/dist/katex.min.css"

import { ColorGray900Alpha15 } from "../../gatsby-plugin-theme-ui"

export interface IPropsPostStyling {
  "data-language"?: string
  children: ReactNode
  className?: string
  href?: string
  rel?: string
  target?: "_blank"
}

const cellStyle: SxStyleProp = {
  p: [3],
  "thead &": {
    py: [4],
    borderBottom: "solid 2px",
    borderColor: "text",
  },
  "tbody tr:last-child &": {
    borderBottom: 0,
  },
  "tbody &": { py: "0.75em" },
  ":first-of-type": {
    pl: [4],
  },
  ":last-child": {
    pr: [4],
  },
  whiteSpace: "nowrap",
}

const headingSpacing: SxStyleProp = {
  "p + &, ul + &, ol + &, pre + &": {
    mt: [12],
  },
  "h1 + &, h2 + &, h3 + &, h4 + &, h5 + &, h6 + &": {
    mt: [8],
  },
}

const tableSpacing: SxStyleProp = {
  "p + &": {
    mt: [6],
  },
  "h1 + &, h2 + &, h3 + &, h4 + &, h5 + &, h6 + &": {
    mt: [8],
  },
  ":not(:last-of-type)": {
    mb: [12],
  },
}

const listSpacing: SxStyleProp = {
  "p + &, h1 + &, h2 + &, h3 + &, h4 + &, h5 + &, h6 + &": {
    mt: [6],
  },
}

export const PostStyling: FC<IPropsPostStyling> = ({ children }) => {
  const components = {
    h1: (props: IPropsPostStyling) => (
      <Heading variant="h1" sx={{ ...headingSpacing }}>
        <Orphans>{props.children}</Orphans>
      </Heading>
    ),
    h2: (props: IPropsPostStyling) => (
      <Heading variant="h2" {...props} sx={{ ...headingSpacing }} />
    ),
    h3: (props: IPropsPostStyling) => (
      <Heading variant="h3" {...props} sx={{ ...headingSpacing }} />
    ),
    h4: (props: IPropsPostStyling) => (
      <Heading variant="h4" {...props} sx={{ ...headingSpacing }} />
    ),
    h5: (props: IPropsPostStyling) => (
      <Heading variant="h5" {...props} sx={{ ...headingSpacing }} />
    ),
    h6: (props: IPropsPostStyling) => (
      <Heading variant="h6" {...props} sx={{ ...headingSpacing }} />
    ),
    p: (props: IPropsPostStyling) => (
      <Text
        as="p"
        variant="body"
        sx={{
          img: {
            maxWidth: "100%",
            "::selection": {
              bg: "backgroundSelection",
            },
          },
          ".gatsby-resp-image-wrapper": {
            my: [8],
          },
        }}
        {...props}
      />
    ),
    ul: (props: IPropsPostStyling) => (
      <Text
        as="ul"
        variant="unorderedList"
        {...props}
        sx={{ ...listSpacing }}
      />
    ),
    ol: (props: IPropsPostStyling) => (
      <Text as="ol" variant="orderedList" {...props} sx={{ ...listSpacing }} />
    ),
    li: (props: IPropsPostStyling) => <Text as="li" {...props}></Text>,
    a: (props: IPropsPostStyling) => {
      const { href, target, rel } = props
      if (!props.className?.includes("gatsby-resp-image-link")) {
        return (
          <Link
            href={href}
            sx={{ textDecoration: "none", color: "textLink", fontWeight: 500 }}
          >
            <Underline {...props} />
          </Link>
        )
      } else {
        return (
          <Box
            as="figure"
            sx={{
              lineHeight: "1.75",
              my: "4em",
            }}
          >
            <Link href={href} target={target} rel={rel}>
              <div {...props} />
            </Link>
          </Box>
        )
      }
    },
    pre: (props: IPropsPostStyling) => {
      const language = props["data-language"]
      return (
        <Text
          as="pre"
          variant="code"
          sx={{
            position: "relative",
            my: [7],
            ["--vscode-highlight-border-radius"]: "6px",
            ["--vscode-highlight-padding-top"]: "32px",
            ["--vscode-highlight-padding-bottom"]: "32px",
            ["--grvsc-padding-v"]: ["16px", null, null, null, "24px", "32px"],
            ["--grvsc-padding-h"]: ["16px", null, null, null, "24px", "32px"],
            width: "100%",
          }}
          className={props.className}
        >
          {language && (
            <Badge
              variant="primaryDark"
              sx={{ position: "absolute", top: 3, right: 3, zIndex: 1 }}
            >
              {language}
            </Badge>
          )}
          <Box sx={{ overflow: "auto" }}>{props.children}</Box>
        </Text>
      )
    },
    table: (props: IPropsPostStyling) => (
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          ...tableSpacing,
        }}
      >
        <Box as="table" sx={{ width: "100%" }} {...props} />
      </Box>
    ),
    thead: (props: IPropsPostStyling) => (
      <Box
        as="thead"
        sx={{
          fontSize: [4, 5, 6],
        }}
        {...props}
      />
    ),
    tbody: (props: IPropsPostStyling) => (
      <Box
        as="tbody"
        {...props}
        sx={{
          fontSize: [3, 4, 5],
        }}
      />
    ),
    tr: (props: IPropsPostStyling) => (
      <Box
        as="tr"
        sx={{
          "tbody &:nth-of-type(odd)": {
            bg: "backgroundRow",
          },
          "tbody &:hover": {
            bg: "backgroundSelection",
          },
        }}
        {...props}
      />
    ),
    td: (props: IPropsPostStyling) => (
      <Text as="td" variant="td" sx={{ ...cellStyle }} {...props} />
    ),
    th: (props: IPropsPostStyling) => (
      <Text
        as="th"
        variant="th"
        sx={{
          ...cellStyle,
          "thead &": { textAlign: "left", fontWeight: 500 },
        }}
        {...props}
      />
    ),
    inlineCode: (props: IPropsPostStyling) => <Code {...props} />,
    figcaption: (props: IPropsPostStyling) => (
      <Text
        as="figcaption"
        variant="small"
        sx={{ mb: 12, mt: [-9, -10, -9, -11], textAlign: "center" }}
        {...props}
      />
    ),
    blockquote: (props: IPropsPostStyling) => (
      <Text as="blockquote" variant="blockquote" sx={{ mt: [10], mb: [12] }}>
        <IconQuote
          sx={{
            width: 80,
            height: "auto",
            mb: [3],
            color: ColorGray900Alpha15,
          }}
        />
        {props.children}
      </Text>
    ),
  }
  return <MDXProvider components={components}>{children}</MDXProvider>
}
